<content-state
  [isLoading]="isLoading$ | async"
  [isEmpty]="!timeOffBalanceFields?.inPolicy?.length && !timeOffBalanceFields?.notInPolicy?.length"
>
  <table class="table-fixed w-full border border-solid border-grey-200 [&_td]:p-2 [&_td]:first:pl-3 [&_td]:last:pr-3">
    <thead class="bg-grey-100 font-semibold text-grey-800">
      <tr>
        <td>{{ 'Time off balance' | translate }}</td>
        <td>{{ 'Balance accrual' | translate }}</td>
      </tr>
    </thead>
    <tbody class="text-grey-600 bg-white [&_tr]:border-0 [&_tr]:border-y [&_tr]:border-solid [&_tr]:border-grey-200">
      <ng-container *ngIf="timeOffBalanceFields?.inPolicy?.length">
        <tr class="bg-grey-50 font-semibold text-base">
          <td colspan="2">
            {{ 'Balances in active absence policy:' | translate }} {{ (absencePolicy$ | async)?.name }}
          </td>
        </tr>
        <tr
          *ngFor="let balanceField of timeOffBalanceFields?.inPolicy; trackBy: trackByBalance"
          app-time-off-accrual-table-row
          [balance]="balanceField"
          [formArray]="timeOffAccrualFormArray"
          (setDefaultAccrual)="setDefaultAccrual($event)"
        ></tr>
      </ng-container>
      <ng-container *ngIf="timeOffBalanceFields?.notInPolicy?.length">
        <tr class="bg-grey-50 text-base">
          <td colspan="2">
            <a class="link" (click)="toggleShowBalancesOutsidePolicy()">
              {{
                showBalancesOutsidePolicy
                  ? ('Hide time off balances outside absence policy' | translate)
                  : ('Show time off balances outside absence policy' | translate)
              }}
            </a>
          </td>
        </tr>
        <ng-container *ngIf="showBalancesOutsidePolicy">
          <tr class="bg-grey-50 font-semibold text-base">
            <td colspan="2">{{ 'Balances outside active absence policy' | translate }}</td>
          </tr>
          <tr
            *ngFor="let balanceField of timeOffBalanceFields?.notInPolicy; trackBy: trackByBalance"
            app-time-off-accrual-table-row
            [balance]="balanceField"
            [formArray]="timeOffAccrualFormArray"
            (setDefaultAccrual)="setDefaultAccrual($event)"
          ></tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</content-state>
