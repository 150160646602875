import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { IconButtonComponent, IconComponent } from '@sb/ui';

import { TimeOffAccrualCalculationInputComponent } from '../time-off-accrual/time-off-accrual-calculation-input.component';
import { TimeOffBalanceFieldModel } from './time-off-accrual-table.component';

@Component({
  selector: 'tr[app-time-off-accrual-table-row]',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TimeOffAccrualCalculationInputComponent,
    ReactiveFormsModule,
    TooltipModule,
    IconButtonComponent,
    IconComponent,
  ],
  templateUrl: './time-off-accrual-table-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeOffAccrualTableRowComponent {
  @Input()
  balance: TimeOffBalanceFieldModel;

  @Input()
  formArray: UntypedFormArray;

  @Output()
  setDefaultAccrual: EventEmitter<TimeOffBalanceFieldModel> = new EventEmitter<TimeOffBalanceFieldModel>();
}
