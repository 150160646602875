import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/forms/validation.service';
import { AppState } from '@app/reducers';
import {
  AbsencePolicyModel,
  TimeOffAccrual as TimeOffAccrualSource,
} from '@app/reducers/orm/absence-policy/absence-policy.model';
import {
  selectIsLoading as selectIsLoadingAbsencePolecies,
  selectPolicyById,
} from '@app/reducers/orm/absence-policy/absence-policy.selectors';
import { TimeOffAccrual } from '@app/reducers/orm/contract/contract.model';
import { TimeOffBalanceModel } from '@app/reducers/orm/time-off-balance/time-off-balance.model';
import { getActiveTimeOffBalances } from '@app/reducers/orm/time-off-balance/time-off-balance.selectors.ts';
import { ContentStateComponent } from '@app/shared/content-state/content-state.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { TimeOffAccrualTableRowComponent } from './time-off-accrual-table-row.component';

export interface TimeOffBalanceFieldModel extends TimeOffBalanceModel {
  formIndex: number;
}

interface BalanceFieldGroups {
  inPolicy: TimeOffBalanceFieldModel[];
  notInPolicy: TimeOffBalanceFieldModel[];
}

@Component({
  selector: 'app-time-off-accrual-table',
  standalone: true,
  imports: [CommonModule, TranslateModule, ContentStateComponent, TimeOffAccrualTableRowComponent],
  templateUrl: './time-off-accrual-table.component.html',
})
export class TimeOffAccrualTableComponent implements OnInit, OnChanges, OnDestroy {
  private absencePolicyId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private timeOffBalances$: Observable<TimeOffBalanceModel[]>;
  private destroyed$ = new Subject<void>();

  private timeOffBalances: TimeOffBalanceModel[] = [];
  private balanceIdsInPolicy: string[] = [];

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public absencePolicyId: string;

  @Input()
  public timeOffAccrual: TimeOffAccrual;

  public timeOffBalanceFields: BalanceFieldGroups;
  public showBalancesOutsidePolicy = false;
  public disableAccrual: boolean;

  public absencePolicy$: Observable<AbsencePolicyModel>;
  public isLoading$: Observable<boolean>;

  public constructor(private store: Store<AppState>) {
    this.timeOffBalances$ = this.store.select(getActiveTimeOffBalances);

    this.absencePolicy$ = this.absencePolicyId$.pipe(switchMap((id) => this.store.select(selectPolicyById(id))));
    this.isLoading$ = this.store.select(selectIsLoadingAbsencePolecies);
  }

  public ngOnInit(): void {
    void combineLatest([this.absencePolicy$, this.timeOffBalances$])
      .pipe(
        filter(([absencePolicy]) => !!absencePolicy),
        takeUntil(this.destroyed$),
      )
      .subscribe(([absencePolicy, timeOffBalances]) => {
        this.timeOffBalances = timeOffBalances;
        this.balanceIdsInPolicy = absencePolicy.configuration.reduce((acc, curr) => [...acc, ...curr.balanceIds], []);
        this.disableAccrual = absencePolicy.timeOffAccrualSourceHours === TimeOffAccrualSource.NONE;
        this.createFormFields();
        this.setFormStatus();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['timeOffAccrual'] && this.form && !changes['timeOffAccrual'].firstChange) {
      this.patchTimeOffAccrual();
    }
    if (changes['absencePolicyId']) {
      this.absencePolicyId$.next(this.absencePolicyId);
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private patchTimeOffAccrual(): void {
    const patchValue = this.timeOffBalances.map((balance) => ({
      id: balance.id,
      value: this.getTimeOffAccrualValue(balance, this.isBalanceInPolicy(balance)),
    }));
    this.timeOffAccrualFormArray?.patchValue(patchValue);
  }

  public setDefaultAccrual(balance: TimeOffBalanceModel): void {
    const patchValue = this.timeOffAccrualFormArray.value.map((timeOffBalance: { id: string; value: number }) =>
      timeOffBalance.id === balance.id ? { id: timeOffBalance.id, value: balance.default_accrual } : timeOffBalance,
    );
    this.timeOffAccrualFormArray?.patchValue(patchValue);
  }

  public toggleShowBalancesOutsidePolicy(): void {
    this.showBalancesOutsidePolicy = !this.showBalancesOutsidePolicy;
  }

  public get timeOffAccrualFormArray(): UntypedFormArray {
    return this.form?.get('time_off_accrual') as UntypedFormArray;
  }

  private setFormStatus(): void {
    if (this.disableAccrual) {
      this.timeOffAccrualFormArray?.disable();
    } else {
      this.timeOffAccrualFormArray?.enable();
    }
  }

  private getTimeOffAccrualValue(timeOffBalance: TimeOffBalanceModel, isInPolicy: boolean): number {
    if (this.timeOffAccrual && Object.hasOwn(this.timeOffAccrual, timeOffBalance.id)) {
      return this.timeOffAccrual[timeOffBalance.id];
    }
    // if in policy set default else set 0
    return isInPolicy ? timeOffBalance?.default_accrual : 0;
  }

  private isBalanceInPolicy(balance: TimeOffBalanceModel): boolean {
    return this.balanceIdsInPolicy.includes(balance.id);
  }

  private createFormFields(): void {
    if (this.form.contains('time_off_accrual')) {
      this.form.removeControl('time_off_accrual');
    }

    const formArray = new UntypedFormArray([]);
    const timeOffBalanceFields: BalanceFieldGroups = {
      inPolicy: [],
      notInPolicy: [],
    };

    this.timeOffBalances.forEach((timeOffBalance, index) => {
      const isInPolicy = this.isBalanceInPolicy(timeOffBalance);
      const timeOffAccrualValue = this.getTimeOffAccrualValue(timeOffBalance, isInPolicy);

      const form = new UntypedFormGroup({
        id: new UntypedFormControl(timeOffBalance.id),
        value: new UntypedFormControl(timeOffAccrualValue, [
          Validators.required,
          ValidationService.negativeNumberValidator,
        ]),
      });

      if (isInPolicy) {
        timeOffBalanceFields.inPolicy.push({
          ...timeOffBalance,
          formIndex: index,
        });
      } else {
        timeOffBalanceFields.notInPolicy.push({
          ...timeOffBalance,
          formIndex: index,
        });

        // open showBalancesOutsidePolicy if a balance outside a policy has a value
        if (!this.showBalancesOutsidePolicy && form.value.value > 0) {
          this.showBalancesOutsidePolicy = true;
        }
      }

      formArray.push(form);
    });

    this.timeOffBalanceFields = timeOffBalanceFields;
    this.form.addControl('time_off_accrual', formArray);
    this.timeOffAccrualFormArray.setValue(formArray.value);
    this.setFormStatus();
  }

  public trackByBalance(_: number, item: TimeOffBalanceFieldModel): string {
    return item.id;
  }
}
