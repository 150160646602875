<td>{{ balance.name }}</td>
<td>
  <div class="flex items-center">
    <time-off-accrual-calculation-input
      [formControl]="formArray?.controls[balance.formIndex]?.get('value')"
    ></time-off-accrual-calculation-input>
    <button
      sb-icon-button
      [sbTooltip]="'Restore the accrual factor to its default value as defined in the balance settings.' | translate"
      [color]="'orange'"
      type="button"
      class="ml-3"
      (click)="setDefaultAccrual.emit(balance)"
    >
      <sb-icon [name]="'history'"></sb-icon>
    </button>
  </div>
</td>
